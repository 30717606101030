import React from "react";
import styled from "styled-components";

import BlackImage from "../images/reserve/black.png";
import WhiteImage from "../images/reserve/white.jpg";
import GreenImage from "../images/reserve/green.png";
import PinkImage from "../images/reserve/pink.png";
import BlueImage from "../images/reserve/blue.png";

import WhitePicker from "../images/reserve/color_picker/white.png";
import WhiteSelectedPicker from "../images/reserve/color_picker/white-selected.png";
import BlackPicker from "../images/reserve/color_picker/black.png";
import BlackSelectedPicker from "../images/reserve/color_picker/black-selected.png";
import BluePicker from "../images/reserve/color_picker/blue.png";
import BlueSelectedPicker from "../images/reserve/color_picker/blue-selected.png";
import PinkPicker from "../images/reserve/color_picker/pink.png";
import PinkSelectedPicker from "../images/reserve/color_picker/pink-selected.png";
import GreenPicker from "../images/reserve/color_picker/green.png";
import GreenSelectedPicker from "../images/reserve/color_picker/green-selected.png";

import SmallBlackImage from "../images/reserve/black_small_d.jpg";
import MediumBlackImage from "../images/reserve/black_medium_d.jpg";
import LargeBlackImage from "../images/reserve/black_large_d.jpg";

import SmallWhiteImage from "../images/reserve/white_small_d.jpg";
import MediumWhiteImage from "../images/reserve/white_medium_d.jpg";
import LargeWhiteImage from "../images/reserve/white_large_d.jpg";

import SmallBlueImage from "../images/reserve/blue_small_d.jpg";
import MediumBlueImage from "../images/reserve/blue_medium_d.jpg";
import LargeBlueImage from "../images/reserve/blue_large_d.jpg";

import SmallPinkImage from "../images/reserve/pink_small_d.jpg";
import MediumPinkImage from "../images/reserve/pink_medium_d.jpg";
import LargePinkImage from "../images/reserve/pink_large_d.jpg";

import SmallGreenImage from "../images/reserve/green_small_d.jpg";
import MediumGreenImage from "../images/reserve/green_medium_d.jpg";
import LargeGreenImage from "../images/reserve/green_large_d.jpg";

const StyledReserveForm = styled.form``;

const FormNavbar = styled.ul`
  list-style: none;
  display: flex;
  font-size: 14px;
`;

const UnoFormButton = styled.button`
  text-transform: uppercase;
  background: ${props => props.background};
  color: ${props => props.color};
  padding: 10px 10px;
  width: 100%;
  border-radius: 50px;
  letter-spacing: 2px;
`;

const UnoFormSubmit = styled.button`
  text-transform: uppercase;
  background: #003dcf;
  color: #fff;
  padding: 5px 10px;
  width: 100%;
  border-radius: 50px;
  letter-spacing: 5px;
  border: none;
  font-size: 14px;
`;

const FormNavbarItem = styled.li`
  flex: 1 0 auto;
  background: #dedede;
  color: #676767;
  padding: 4px 5px;
  text-transform: uppercase;

  ${props =>
    props.active &&
    `
        color: #efefef;
        background: blue;
    `};
`;
const StageTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin: 20px auto;
`;

const CupImage = styled.img`
  width: 100%;
  user-select: none;
  height: 80vh;
  object-fit: cover;
  object-position: 50% 100%;
  background-size: 150%;
`;

const UnoColorPicker = styled.ul`
  margin: 20px auto;
  display: flex;
  width: 100%;
`;

const UnoColorOption = styled.li`
  flex: 1 1 auto;
  justify-content: space-between;
  // margin-right: 10px;
  cursor: pointer;
  border-radius: 100%;
  border: 2px solid transparent;
  
  &:hover {
    border-color: #c4c4c4;
  }

  &:last-of-type {
    margin-right: auto;
  }
`;

const ColorPickerOption = styled.img`
  width: 100%;
`;

const UnoSizePicker = styled.ul`
  margin: 20px auto;
  display: flex;
`;
const UnoSizeOption = styled.li`
  flex: 1 0 auto;
  background: #e1e1e1;
  padding: 6px 23px;
  line-height: 1.2;
  text-align: center;
  justify-content: space-between;
  width: 97px;
  // margin-right: 3px;
  cursor: pointer;
  border-radius: 0px;

  &:last-of-type {
    margin-right: auto;
  }
  
  &:hover {
    background: #c4c4c4;
  }

  ${props =>
    props.active &&
    `
    background: #003dcf !important;
    color: white;
  `};
`;

const DesktopWrapper = styled.div`
  display: flex;
  padding-left: 15px;
  padding-right: 15px;
`;
const LeftColumn = styled.div`
  flex: 1 0 35%;
  text-align: left;
`;

const RightColumn = styled.div`
  padding-left: 40px;
  flex: 1 0 65%;
`;

const StyledInput = styled.input`
  font-size: 16px;
  padding: 0 10px;
  height: 42px;
  width: 100%;
  border: none;
`;

const StyledLabel = styled.label`
  font-size: 14px;
  user-select: none;
`;

const StyledCheckbox = styled.input`
  margin-right: 4px;
`;

const PickerWrapper = styled.div`
  ${props =>
    props.disabled &&
    `
    opacity: 0.3;
    pointer-events: none;
    user-select: none;
  `};
`;

const Data = {
  formStages: [
    { title: "" },
    { title: "Choose a color" },
    { title: "Choose a size" },
    { title: "Complete sign up" },
  ],

  cupColors: ["white", "black", "blue", "green", "pink"],
  cupSizes: [
    { title: "Small", capacity: "12 oz.", value: "small" },
    { title: "Medium", capacity: "16 oz.", value: "medium" },
    { title: "Large", capacity: "20 oz.", value: "large" },
  ],

  colorPicker: {
    white: {
      normal: WhitePicker,
      selected: WhiteSelectedPicker,
    },
    black: {
      normal: BlackPicker,
      selected: BlackSelectedPicker,
    },
    blue: {
      normal: BluePicker,
      selected: BlueSelectedPicker,
    },
    green: {
      normal: GreenPicker,
      selected: GreenSelectedPicker,
    },
    pink: {
      normal: PinkPicker,
      selected: PinkSelectedPicker,
    },
  },

  cupImages: {
    black: {
      color: BlackImage,
      small: SmallBlackImage,
      medium: MediumBlackImage,
      large: LargeBlackImage,
    },
    white: {
      color: WhiteImage,
      small: SmallWhiteImage,
      medium: MediumWhiteImage,
      large: LargeWhiteImage,
    },
    blue: {
      color: BlueImage,
      small: SmallBlueImage,
      medium: MediumBlueImage,
      large: LargeBlueImage,
    },
    pink: {
      color: PinkImage,
      small: SmallPinkImage,
      medium: MediumPinkImage,
      large: LargePinkImage,
    },
    green: {
      color: GreenImage,
      small: SmallGreenImage,
      medium: MediumGreenImage,
      large: LargeGreenImage,
    },
  },
};

class ReserveForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hoverColor: "",
      hoverSize: "",
      stage: 1,
      color: "white",
      size: "medium",
      email: "",
      zipCode: "",
      brand: "",
      reserve: false,
      getUpdates: false,
    };
  }

  chooseColor = color => {
    const { stage } = this.state;
    const newStage = Math.max(stage, 2);
    this.setState({ color, stage: newStage });
  };

  chooseSize = size => {
    const { stage } = this.state;
    const newStage = Math.max(stage, 3);
    this.setState({ size, stage: newStage }, () => {});
  };

  chooseStage = stage => {
    this.setState({ stage });
  };

  renderColorPicker() {
    const { color } = this.state;

    return (
      <PickerWrapper
        style={{
          display: "flex",
          alignItems: "center",
          borderTop: "1px solid #dedede",
        }}
      >
        <div
          style={{
            flex: "1 0 auto",
            marginRight: 30,
            fontSize: 14,
            fontFamily: "NewlineBold",
          }}
        >
          Color:
        </div>
        <div style={{ flex: "1 1 auto" }}>
          <UnoColorPicker>
            {Data.cupColors.map(colorOption => {
              const currentColorOption = Data.colorPicker[colorOption];
              return (
                <UnoColorOption
                  key={colorOption}
                  onClick={() => {
                    this.chooseColor(colorOption);
                  }}
                  onMouseOver={() => {
                    this.setState({ hoverColor: colorOption });
                  }}
                  onMouseOut={() => {
                    this.setState({ hoverColor: "" });
                  }}
                >
                  <ColorPickerOption
                    src={
                      currentColorOption[
                        colorOption === color ? "selected" : "normal"
                      ]
                    }
                  />
                </UnoColorOption>
              );
            })}
            <input type="hidden" name="color" value={color} />
          </UnoColorPicker>
        </div>
      </PickerWrapper>
    );
  }

  renderSizePicker() {
    const { size, stage } = this.state;

    return (
      <PickerWrapper
        style={{
          display: "flex",
          alignItems: "center",
          borderTop: "1px solid #dedede",
        }}
        disabled={stage < 2}
      >
        <div
          style={{
            flex: "1 0 auto",
            marginRight: 30,
            fontSize: 14,
            fontFamily: "NewlineBold",
          }}
        >
          Size:
        </div>
        <div style={{ flex: "1 1 auto" }}>
          <UnoSizePicker>
            {Data.cupSizes.map(({ title, capacity, value }) => {
              return (
                <UnoSizeOption
                  key={title}
                  active={value === size}
                  onClick={() => {
                    this.chooseSize(value);
                  }}
                  onMouseOver={() => {
                    this.setState({ hoverSize: value });
                  }}
                  onMouseOut={() => {
                    this.setState({ hoverSize: "" });
                  }}
                >
                  <div style={{ fontFamily: "NewlineTextBold", fontSize: 14 }}>
                    {title}
                  </div>
                  <div style={{ fontSize: 12 }}>{capacity}</div>
                </UnoSizeOption>
              );
            })}
            <input type="hidden" name="size" value={size} />
          </UnoSizePicker>
        </div>
      </PickerWrapper>
    );
  }

  renderDesktopCupImage() {
    const { hoverColor, hoverSize, color, size } = this.state;
    const cupImage = Data.cupImages[hoverColor || color][hoverSize || size];
    return <CupImage src={cupImage} />;
  }

  renderFormDetails() {
    const { reserve, getUpdates, email, zipCode, brand, stage } = this.state;

    return (
      <PickerWrapper disabled={stage < 3}>
        <div
          style={{
            borderTop: "1px solid #dedede",
            paddingTop: 20,
            marginBottom: 10,
          }}
        >
          <StyledInput
            type="email"
            name="email"
            id="email"
            style={{ width: "100%" }}
            required
            value={email}
            onChange={e => this.setState({ email: e.target.value })}
            placeholder="Email Address"
          />
        </div>
        <div style={{ display: "flex" }}>
          <div style={{ flex: "1 0 22%" }}>
            <StyledInput
              type="text"
              name="zipcode"
              pattern="\d*"
              maxLength="5"
              placeholder="Zip Code"
              required
              value={zipCode}
              onChange={e => this.setState({ zipCode: e.target.value })}
            />
          </div>
          <div style={{ flex: "1 0 55%", marginLeft: "2.5%" }}>
            <StyledInput
              type="text"
              name="brand"
              placeholder="Your favorite beverage brand"
              value={brand}
              onChange={e => this.setState({ brand: e.target.value })}
            />
          </div>
        </div>
        {/*
        <div style={{ fontSize: 12, color: "#ababab" }}>
          Why this helps us{" "}
          <span
            style={{
              background: "#dedede",
              color: "#bcbcbc",
              width: 14,
              height: 14,
              lineHeight: "14px",
              borderRadius: "50%",
              textAlign: "center",
              verticalAlign: "middle",
              display: "inline-block",
              paddingLeft: 1,
              cursor: "help",
            }}
            data-toggle="tooltip"
            data-placement="right"
            title="Tooltip on right"
          >
            ?
          </span>
        </div>
          */}
        <div style={{ lineHeight: 1, margin: "10px auto" }}>
          <StyledCheckbox
            id="reserve_cup"
            name="reserve_cup"
            type="checkbox"
            checked={reserve}
            required
            value={reserve}
            onChange={() => {
              this.setState(({ reserve }) => ({
                reserve: !reserve,
              }));
            }}
          />
          <StyledLabel htmlFor="reserve_cup">
            Reserve my Uno Cup for free.{" "}
            <a href="/reserve-terms/" target="blank">
              See Terms
            </a>
            .
          </StyledLabel>
          <br />
          <StyledCheckbox
            id="subscribe_updates"
            name="subscribe_updates"
            type="checkbox"
            checked={getUpdates}
            value={getUpdates}
            onChange={() => {
              this.setState(({ getUpdates }) => ({
                getUpdates: !getUpdates,
              }));
            }}
          />

          <StyledLabel htmlFor="subscribe_updates">
            Get periodical updates.
          </StyledLabel>

          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="reserve" />
        </div>
        <UnoFormSubmit type="submit">Sign Up</UnoFormSubmit>
      </PickerWrapper>
    );
  }
  render() {
    return (
      <StyledReserveForm
        name="reserve"
        action="/thank-you"
        method="POST"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
      >
        <DesktopWrapper>
          <LeftColumn>
            <div
              style={{
                fontSize: 24,
                marginBottom: 20,
                fontFamily: "NewlineBold",
              }}
            >
              Reserve your Uno. It's free.
            </div>
            <div style={{ fontSize: 14, lineHeight: 1.4, marginBottom: 20 }}>
              Join the tens of thousands of people who have committed to a zero
              waste world. Reserving your Uno Cup helps us partner with many
              more merchants so you can use it everywhere.
            </div>
            <div>{this.renderColorPicker()}</div>
            <div>{this.renderSizePicker()}</div>
            <div>{this.renderFormDetails()}</div>
          </LeftColumn>
          <RightColumn>{this.renderDesktopCupImage()}</RightColumn>
        </DesktopWrapper>
      </StyledReserveForm>
    );
  }
}

export default ReserveForm;
