import React from "react";

import Layout from "../components/layout";
import UnoSection from "../components/uno-section";
import ReserveForm from "../components/reserve-form";

const ReservePage = () => (
  <Layout currentPage="reserve">
    <UnoSection verticalCenter minHeight="100vh" maxWidth="800px" bottomPadding topPadding="100px">
      <ReserveForm />
    </UnoSection>
  </Layout>
);

export default ReservePage;
